.snake-playground {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.snake-playground canvas {
  box-shadow: 0 0 4px var(--main-color);
}