.snake-app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.snake-app h2 {
  margin: auto;
  padding: 10px;
}
