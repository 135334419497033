@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,700');

html[data-theme='light'] {
  --background: white;
  --main-color: black;
  --box-shadow: #a5a5a5;
}

html[data-theme='dark'] {
  --background: black;
  --main-color: white;
  --box-shadow: #a5a5a5;
}

body {
  background: var(--background);
  color: var(--main-color);
  font-family: 'Raleway', sans-serif;
  margin: 0;
}
