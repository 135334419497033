@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,700);
html[data-theme='light'] {
  --background: white;
  --main-color: black;
  --box-shadow: #a5a5a5;
}

html[data-theme='dark'] {
  --background: black;
  --main-color: white;
  --box-shadow: #a5a5a5;
}

body {
  background: var(--background);
  color: var(--main-color);
  font-family: 'Raleway', sans-serif;
  margin: 0;
}

.snake-app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.snake-app h2 {
  margin: auto;
  padding: 10px;
}

.game-controls {
  align-items: center;
  background: var(--background);
  display: flex;
  justify-content: center;
  z-index: 10;
}

.game-controls .col-1 {
  display: flex;
  flex-direction: column;
}

.game-controls .col-1 span:first-child {
  padding: 10px 10px 0;
}

.game-controls .col-1 span:last-child {
  padding: 10px;
}

.game-controls a {
  border: 1px dashed var(--main-color);
  color: var(--main-color);
  padding: 5px;
  text-decoration: none;
}

.game-stats {
  display: flex;
  justify-content: center;
  box-shadow: 0px 4px 8px 0px #a5a5a5;
}

.game-stats span {
  padding: 10px;
}
.game-credits {
  align-items: center;
  box-shadow: 0 0 8px #a5a5a5;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 15px;
}
.snake-playground {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.snake-playground canvas {
  box-shadow: 0 0 4px var(--main-color);
}
