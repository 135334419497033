.game-controls {
  align-items: center;
  background: var(--background);
  display: flex;
  justify-content: center;
  z-index: 10;
}

.game-controls .col-1 {
  display: flex;
  flex-direction: column;
}

.game-controls .col-1 span:first-child {
  padding: 10px 10px 0;
}

.game-controls .col-1 span:last-child {
  padding: 10px;
}

.game-controls a {
  border: 1px dashed var(--main-color);
  color: var(--main-color);
  padding: 5px;
  text-decoration: none;
}
